
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/community/[id]/[slug]/[...params]",
      function () {
        return require("private-next-pages/community/[id]/[slug]/[...params].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/community/[id]/[slug]/[...params]"])
      });
    }
  